import React from "react";
import Layout from "../components/layout/layout";

export default function ContactoEnviado() {
    return (
        <React.Fragment>
            <Layout pageId="contact-sent">
                <center>
                    <br></br>
                    <br></br>
                    <br></br>

                    <h2>Gracias por contactarnos</h2>
                    <br></br>
                    <br></br>
                    <h4>
                        En el plazo de 72 horas nos pondremos en contacto
                        contigo
                    </h4>
                    <br></br>
                    <br></br>
                    <br></br>
                </center>
            </Layout>
        </React.Fragment>
    );
}
